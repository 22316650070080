import React, { useEffect } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { Icon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { SimpleDataTable, SimpleTableCell } from '@/components';
import DragIcon from 'ASSETS/images/cdd30/layout/sort/drag.svg';
import DisabledDragIcon from 'ASSETS/images/layout/sort/disabled_drag.svg';
import { Img, A } from '@/shared/components/sanitizedTags';
import { CDD } from '@/typedJS';
// Scott: this is the best way to import sass, but it doesn't work w/ webpacker 3. revisit after uggrade.
// See https://www.pivotaltracker.com/story/show/182980152
// import './ReorderDataTable.sass';

type Rows = SimpleDataTable['props']['rows'];

type Props = SimpleDataTable['props'] & {
  rowIdPrefix?: string;
  numFixedRows?: number;
  showDelete?: boolean;
  showHeader?: boolean;
  style?: React.CSSProperties;
  hideDeleteForRow?: (row: Rows[number]) => boolean;
  hideReorderForRow?: (row: Rows[number]) => boolean;

  onDeleteRow?: (row: Rows[number], index: number) => void;
  onChangeOrder: (rows: Rows) => void;
  renderCustomRow?: (row: Rows[number], index: number) => React.ReactNode | void;
}

const arrangeItemsAfterDragEndFromIndexToIndex = (rows: Rows, sourceIndex: number, destinationIndex: number) => {
  const newItems = [...rows];
  const movedItem = newItems[sourceIndex];
  newItems.splice(sourceIndex, 1);
  newItems.splice(destinationIndex, 0, movedItem);
  return newItems;
};

export const arrangeItemsAfterDragEnd = (rows: Rows, result: DropResult) => {
  if (!result.destination) {
    return null;
  }

  if (result.destination.index === result.source.index) {
    return null;
  }

  return arrangeItemsAfterDragEndFromIndexToIndex(rows, result.source.index, result.destination.index);
};

export const ReorderDataTable = (props: Props) => {
  const {
    rows, columns, showDelete,
    hideDeleteForRow, hideReorderForRow,
    onChangeOrder, onClickRow, onDeleteRow,
    showHeader = true,
    style,
  } = props;

  useEffect(() => {
    // Scott: allow integration tests to call the reorder function without simulating drag and drop.
    // example: ReorderDataTable.reorder(0, 1);
    if (CDD.testEnvironment) {
      const w = window as any;
      if (!w.ReorderDataTable) {
        w.ReorderDataTable = {};
      }
      w.ReorderDataTable.reorder = (from: number, to: number) => {
        onChangeOrder(arrangeItemsAfterDragEndFromIndexToIndex(rows, from, to));
      };
    }

    return () => {
      if (CDD.testEnvironment) {
        const w = window as any;
        w.ReorderDataTable = null;
      }
    };
  }, [onChangeOrder, rows]);

  const handleDeleteRow = (row: Rows[number], index: number) => {
    if (onDeleteRow) {
      onDeleteRow(row, index);
    } else {
      const newRows = rows.slice();
      newRows.splice(index, 1);
      onChangeOrder(newRows);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    const newItems = arrangeItemsAfterDragEnd(rows, result);
    if (newItems) {
      onChangeOrder(newItems);
    }
  };

  const renderRow = (item: Rows[number], index: number) => {
    const { numFixedRows, getRowAttributes } = props;
    const customRenderRow = props.renderCustomRow?.(rows[index], index);
    if (customRenderRow) {
      return customRenderRow;
    }
    const isFixedRow = (index < (numFixedRows ?? 0)) || (hideReorderForRow?.(item) ?? false);
    const isUnremovable = isFixedRow || (hideDeleteForRow?.(item) ?? false);
    const removeClassnames = isUnremovable ? 'cancel disabled' : 'cancel';
    if (isFixedRow) {
      return <TableRow key={item.id}>
        {columns.map((column) => <SimpleTableCell key={column.id} {...props} row={rows[index]} id={column.id} />)}
        <TableCell align='right' className={'inner actions'}>
          {showDelete
            ? <A
              className={removeClassnames}
              name="remove"
              disabled={isUnremovable}
              onClick={() => { /**/ }}
              href="#"
            >Remove</A>
            : null}

          <span className='disabled'>
            <Img src={DisabledDragIcon} />
          </span>
        </TableCell>
      </TableRow>;
    }
    const { rowIdPrefix = '' } = props;
    return <Draggable
      key={'' + (item.id ?? index)}
      draggableId={'' + item.id}
      index={index}>
      {(
        draggableProvided: DraggableProvided,
        snapshot: DraggableStateSnapshot,
      ) => {
        return (
          <>
            <TableRow className='row' id={rowIdPrefix + item.id}
              onClick={() => {
                onClickRow?.(rows[index], index);
              }}
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...getRowAttributes?.(rows[index])}
              style={{
                ...draggableProvided.draggableProps.style,
                background: snapshot.isDragging
                  ? 'rgba(245,245,245, 0.75)'
                  : 'none',
              }}
            >
              {snapshot.isDragging && colgroup}
              {columns.map((column) => <SimpleTableCell key={column.id}
                {...props} row={rows[index]} id={column.id} className={column.className} />)}

              <TableCell align='right' className={`inner actions${isFixedRow ? ' hidden' : ''}`}>
                {(!!showDelete && !snapshot.isDragging) &&
                  <A
                    className={removeClassnames}
                    name="remove"
                    disabled={isUnremovable}
                    onClick={isUnremovable ? null : () => { handleDeleteRow(rows[index], index); }}
                    href="#"
                  >Remove</A>}

                <span {...draggableProvided.dragHandleProps} className='drag-handle'>
                  {!snapshot.isDragging &&
                    <Icon>
                      <Img src={DragIcon} />
                    </Icon>}
                </span>
              </TableCell>
            </TableRow>
          </>
        );
      }}
    </Draggable>;
  };

  const colgroup = <colgroup>
    {columns.map(column =>
      <col key={column.id} width={column.width} />)}
    <col width="20" />
  </colgroup>;

  return (
    <TableContainer className='ReorderDataTable'>
      <Table className={props.className}>
        {/* This enforces column widths: */}
        {colgroup}
        {showHeader && <TableHead className='table-head-row'>
          <TableRow>
            {columns.map(column =>
              <TableCell key={column.id} id={'' + column.id} style={column.style ?? {}}>{column.label}</TableCell>)}
            <TableCell />
          </TableRow>
        </TableHead>}

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable' direction='vertical' >
            {(droppableProvided: DroppableProvided) => (
              <TableBody
                data-testid='reorder-data-table'
                style={style}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}>
                {rows.map((item, index: number) => renderRow(item, index))}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};
