import axios from 'axios';
import { pathFor } from '@/shared/utils/routes.js';
import camelCaseKeys from '@/shared/utils/camelCaseKeys.js';
import activeAjax from 'ASSETS/javascripts/activeAjax.js';
import { CDD } from '@/typedJS';
import { MarvinCML } from '@cdd/ui-kit/lib/components/elements/molImage/v2/MarvinCML';

const DEHUMANIZE_NAMES = {
  bromine: 'dibromine',
  sodiumcyanoborohydride: '25895-60-7',
  palladiumacetate: 'palladium diacetate',
  hydrogen: 'dihydrogen',
};

const dehumanizeNames = (name) => {
  return DEHUMANIZE_NAMES[name.replace(/[\s-]/g, '').toLowerCase()] || name;
};

// First try to find it by ID.  Then try to find it by name.
const getMoleculeMrv = (identifier) => {
  return getMoleculeMrvById(identifier).catch(() => getMoleculeMrvByName(identifier));
};

const getMoleculeMrvById = (id) => {
  return getMoleculeDataById(id).then(data => data.mrv);
};

const getMoleculeDataById = (id) => {
  return axios.get(
    `${pathFor({ type: 'molecule', id: id.trim() })}`,
    { params: { format: 'json' } },
  ).then(response => {
    return camelCaseKeys(response.data);
  });
};

const getMoleculeMrvByName = (name) => {
  activeAjax.start();

  const url = `/${CDD.ActiveDataContext.toContextParam}/name_to_structure`;
  return axios.get(
    url,
    { params: { text: name } },
  ).then(response => {
    if (!response.data) return null;
    const mrv = MarvinCML.fromMolfile(response.data.structure);
    return mrv.serialize();
  }).finally(() => {
    activeAjax.stop();
  });
};

const getMoleculeProperties = (mrv) => {
  return axios.post(
    CDD.StructureEditor.marvin4JSServices().detailws,
    {
      exportStructure: true,
      exportFormat: 'name',
      singleFragmentMode: false,
      structure: mrv,
    },
  ).then(response => {
    const data = response.data[0];
    const { formula, mass } = data;
    const { structure } = data.structure;
    return {
      formula,
      molecularWeight: mass,
      iupacName: structure,
    };
  });
};

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const getCASNumber = (mrv) => {
  return null;
};

const earliestCASNumber = (casNumbers) => {
  if (casNumbers.length == 0) return null;
  return casNumbers.map(s => {
    s = '0000000000000' + s;
    return s.substr(s.length - 12);
  }).sort()[0].replace(/^0+/, '');
};

const internals = {
  earliestCASNumber,
  dehumanizeNames,
  DEHUMANIZE_NAMES,
};

export {
  internals,
  getMoleculeMrv,
  getMoleculeDataById,
  getMoleculeMrvByName,
  getCASNumber,
  getMoleculeProperties,
};
