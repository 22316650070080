import { DDFormUtils } from '@/shared/components/DDForm/DDFormUtils';
import { A } from '@/shared/components/sanitizedTags.js';
import { AnyObject, StringOrNumber } from '@/types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
// Scott: this is the best way to import sass, but it doesn't work w/ webpacker 3. revisit after uggrade.
// See https://www.pivotaltracker.com/story/show/182980152
// import './SimpleDataTable.sass';

export type ColumnSortDef<TColID extends StringOrNumber = StringOrNumber> = {
  id: TColID;
  direction?: 'asc' | 'desc' | null;
};

export type ColumnDef<TColID extends StringOrNumber = StringOrNumber> = {
  id: TColID;
  label: string;
  isSortable?: boolean;
  width?: number | string;
  style?: React.CSSProperties;
  type?: 'default' | 'email' | 'date' | 'link' | 'checkmark' | 'pickList';
  hideIfEmpty?: boolean;
  className?: string;
};

export type SimpleDataTableRenderType =
  | ReactElement
  | undefined
  | string
  | number;

export type SimpleDataTableProps<
  TColID extends StringOrNumber = StringOrNumber
> = {
  columns: Array<ColumnDef<TColID>>;
  rows: Array<AnyObject>;
  ariaLabel?: string;
  isSortable?: boolean;
  stickyHeaders?: boolean;
  presorted?: boolean;
  sortBy?: ColumnSortDef;
  setSortBy?: (sorting: ColumnSortDef) => void;
  alternateRowColors?: boolean;
  showHover?: boolean;
  className?: string;
  infiniteScrollHeight?: number;
  collapsedHeight?: number;

  onClickColumn?: (id: TColID) => boolean;
  onClickRow?: (row: AnyObject, index: number) => void;
  renderCell?: (columnId: TColID, data: AnyObject) => SimpleDataTableRenderType;
  getFieldValue?: (
    columnId: TColID,
    row1: AnyObject
  ) => StringOrNumber | undefined;
  getRowHref?: (row: AnyObject) => string | undefined;
  getRowClassName?: (row: AnyObject) => string | undefined;
  getRowAttributes?: (row: AnyObject) => AnyObject;
  compareRows?: (columnId: TColID, row1: AnyObject, row2: AnyObject) => number;
  renderCustomHeaderCell?: (
    column: ColumnDef<TColID>
  ) => SimpleDataTableRenderType;
  renderHeaderRow?: () => React.ReactElement;
};

export const getDataTableFieldValue = (
  props: Pick<SimpleDataTableProps, 'getFieldValue' | 'columns'>,
  id: StringOrNumber,
  row: AnyObject,
): StringOrNumber | JSX.Element | undefined => {
  if (props.getFieldValue) {
    const result = props.getFieldValue(id, row);
    // if the supplied getFieldValue() returns undefined, fall through to default
    if (result !== undefined) {
      return result;
    }
  }
  const column = props.columns.find((column) => column.id === id);
  const result = DDFormUtils.getValue('' + id, row);

  switch (column?.type) {
    case 'date': {
      const dateVal = moment(result).toDate().getTime();

      return isNaN(dateVal) ? Infinity : dateVal;
    }
  }
  return result as StringOrNumber;
};

@observer
export class SimpleTableHeaderCell extends React.Component<
  Omit<SimpleDataTableProps, 'rows'> & { id: StringOrNumber }
> {
  constructor(props) {
    super(props);
    makeObservable(this, {
      style: computed,
      column: computed,
    });
  }

  get column() {
    return this.props.columns.find((column) => column.id === this.props.id);
  }

  get style() {
    const column = this.column;
    const style: React.CSSProperties = column.style ?? {};

    if (column.isSortable === false) {
      style.pointerEvents = 'none';
    }

    return style;
  }

  handleClickColumn = () => {
    const { id, sortBy } = this.props;

    const column = this.column;
    if (column) {
      if (column.isSortable !== false) {
        if (this.props.onClickColumn?.(id)) {
          // click was handled
          return;
        }
        const direction =
          id === sortBy?.id && sortBy?.direction === 'asc' ? 'desc' : 'asc';

        this.props.setSortBy?.({ id, direction });
      }
    }
  };

  render() {
    const {
      column,
      props: { id, sortBy, renderCustomHeaderCell },
    } = this;

    let contents = renderCustomHeaderCell && renderCustomHeaderCell(column);

    if (!contents) {
      if (column.isSortable === false || this.props.isSortable === false) {
        contents = column.label;
      } else {
        contents = (
          <TableSortLabel
            className='search_results__item__sort'
            data-id={id}
            active={id === sortBy?.id}
            direction={id === sortBy?.id ? sortBy?.direction : undefined}
            onClick={this.handleClickColumn}
          >
            {column.label}
          </TableSortLabel>
        );
      }
    }

    return (
      <TableCell key={id} style={this.style}>
        {contents}
      </TableCell>
    );
  }
}

export class SimpleTableCell extends React.Component<
  Omit<SimpleDataTableProps, 'rows'> & {
    id: StringOrNumber;
    row: AnyObject;
    className?: string;
  }
> {
  render() {
    const { columns, row, id, className } = this.props;
    const column = columns.find((column) => column.id === id);

    const href = this.props.getRowHref?.(row);
    let wrapInRowLink = !!href;

    const renderContents = (): StringOrNumber | JSX.Element => {
      const result = getDataTableFieldValue(this.props, id, row);

      switch (column?.type) {
        case 'date': {
          const date = new Date(result as number);
          if (isNaN(date.getTime())) {
            return '';
          }
          return moment(result as number).format('MMMM D, YYYY');
        }

        case 'email':
          wrapInRowLink = false;
          return <A href={`mailto:${result}`}>{result}</A>;

        case 'link': // show using a link style
          wrapInRowLink = false;
          return <A href={href ?? '#'}>{result}</A>;

        case 'checkmark':
          return result ? '✓' : '';

        default:
          return result;
      }
    };

    let contents = this.props.renderCell?.(id, row) ?? <>{renderContents()}</>;
    if (wrapInRowLink) {
      contents = (
        <A href={href} className='cell-anchor'>
          {contents}
        </A>
      );
    }

    return (
      <TableCell
        key={column.id}
        className={className}
        data-column-id={column.id}
      >
        {contents}
      </TableCell>
    );
  }
}

export class SimpleTableRow extends React.Component<
  SimpleDataTableProps & { index: number; row: AnyObject }
> {
  handleClickRow = () => {
    if (this.props.onClickRow) {
      this.props.onClickRow(
        this.props.row,
        this.props.rows.indexOf(this.props.row),
      );
    }
  };

  render() {
    const { row, columns, getRowClassName, getRowAttributes } = this.props;
    return (
      <TableRow
        onClick={this.handleClickRow}
        className={getRowClassName?.(row)}
        {...getRowAttributes?.(row)}
      >
        {columns.map((column) => {
          return (
            <SimpleTableCell
              key={column.id}
              {...this.props}
              row={row}
              id={column.id}
              className={column.className}
            />
          );
        })}
      </TableRow>
    );
  }
}

@observer
export class SimpleDataTable extends React.Component<SimpleDataTableProps> {
  static defaultProps: Partial<SimpleDataTableProps> = {
    presorted: false,
    stickyHeaders: false,
  };

  rowLimit = 100;

  constructor(props: SimpleDataTableProps) {
    super(props);
    makeObservable(
      this,
      {
        rows: computed,
        columns: computed,
        rowLimit: observable,
      },
      { autoBind: true },
    );
  }

  get columns() {
    const { columns, rows } = this.props;
    const columnsWithData = new Set<StringOrNumber>();
    columns.forEach((column) => {
      if (!column.hideIfEmpty) {
        columnsWithData.add(column.id);
      }
    });

    rows.forEach((row) => {
      columns.forEach((column) => {
        const fieldValue = DDFormUtils.getValue('' + column.id, row);
        if (fieldValue === 0 || !!fieldValue) {
          columnsWithData.add(column.id);
        }
      });
    });

    return this.props.columns.filter((column) =>
      columnsWithData.has(column.id),
    );
  }

  get rows() {
    if (!this.props?.rows) {
      return [];
    }

    if (this.props.presorted) {
      return this.props.rows;
    }

    // perform internal sort
    const result = this.props.rows.slice();
    const {
      props,
      props: { sortBy },
    } = this;

    if (sortBy) {
      const sortedResult = sortResults({ result, props, sortBy });
      return sortedResult;
    }
    return result;
  }

  componentDidUpdate(prevProps: Readonly<SimpleDataTableProps>): void {
    const { columns } = this;

    if (prevProps.columns !== columns) {
      if (!this.props.sortBy) {
        // set default sortBy column if none set from the first sortable column.
        for (let i = 0; i < columns.length; i++) {
          if (columns[i].isSortable !== false) {
            this.props.setSortBy?.({ id: columns[i].id, direction: 'asc' });
            return;
          }
        }
      }
    }
  }

  handleFetchMoreRows = action(() => {
    this.rowLimit += 100;
  });

  render() {
    const { ariaLabel, infiniteScrollHeight, stickyHeaders, renderHeaderRow } =
      this.props;
    const { columns, rows, props } = this;

    const displayRows =
      rows.length > this.rowLimit ? rows.slice(0, this.rowLimit) : rows;

    const {
      alternateRowColors = true,
      showHover = true,
      className = '',
    } = this.props;
    const tableBodyClass = `${
      alternateRowColors ? 'alternate-row-colors' : ''
    } ${showHover ? 'show-hover' : 'no-hover'}`;

    return (
      <InfiniteScroll
        element='div'
        height={infiniteScrollHeight ? `${infiniteScrollHeight}px` : ''}
        pageStart={0}
        loadMore={this.handleFetchMoreRows}
        hasMore={this.props.rows.length > this.rowLimit}
      >
        <Table
          aria-label={ariaLabel}
          className={'SimpleDataTable ' + className}
        >
          <colgroup>
            {columns.map((column) => (
              <col key={column.id} width={column.width} />
            ))}
          </colgroup>

          <TableHead
            style={
              stickyHeaders
                ? { position: 'sticky', top: 0, background: 'white' }
                : {}
            }
          >
            {renderHeaderRow && (
              <TableRow>
                <TableCell
                  className='additional-header-row'
                  style={{ height: this.props.collapsedHeight }}
                  colSpan={columns.length}
                >
                  {renderHeaderRow()}
                </TableCell>
              </TableRow>
            )}

            <TableRow className='header-row'>
              {columns.map((column) => (
                <SimpleTableHeaderCell
                  key={column.id}
                  {...this.props}
                  id={column.id}
                />
              ))}
            </TableRow>
          </TableHead>

          <TableBody className={tableBodyClass}>
            {displayRows.map((row, idx) => (
              <SimpleTableRow
                key={idx}
                {...props}
                columns={columns}
                index={idx}
                row={row}
              />
            ))}
          </TableBody>
        </Table>
      </InfiniteScroll>
    );
  }
}

export function sortResults({
  result,
  props,
  sortBy,
}: {
  result: any;
  props: Pick<SimpleDataTableProps, 'getFieldValue' | 'columns'>;
  sortBy: ColumnSortDef<StringOrNumber>;
}) {
  result.sort((rowA, rowB) => {
    let result;

    const a = getDataTableFieldValue(props, sortBy.id, rowA);
    const b = getDataTableFieldValue(props, sortBy.id, rowB);

    if (typeof a === 'number' && typeof b === 'number') {
      result = a - b;
    } else {
      result = ('' + a).localeCompare('' + b);
    }

    if (sortBy.direction === 'desc') {
      result = -result;
    }
    return result;
  });
  return result;
}
